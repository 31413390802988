"use strict";

export function initInScope ($scope) {
    let $shariff = $scope.find('.js-shariff');
    console.log('sharifffff');

    import('shariff').then(function () {
        new Shariff($shariff, {
            //do stuff
        });
    });
}