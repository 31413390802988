import 'slick-carousel';

export function initInScope ($scope) {
    let $sliders = $scope.find('.js-sujet-slider__slider');
    let $sliderNavItems = $('.js-sujet-slider__nav-item');

    $sliders.each(function() {
        $(this).slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        fade: false,
        draggable: true
        });
    });

    //on click on sidebar nav items, change slider
    $sliderNavItems.on('click', function(e) {
        e.preventDefault();
        $('.sidebar__slider-navigation[data-slide]').removeClass('is-active');
        $(this).addClass('is-active');
        let slideNr = $(this).data('slide');
        $('.js-sujet-slider__slider').slick('slickGoTo', slideNr - 1);
    });

    //on slider change, change sidebar nav items
    $sliders.on('afterChange', function(event, slick, currentSlide){
        let index = $('.js-sujet-slider__slider .slick-current.slick-active').attr("data-slick-index");
        $sliderNavItems.removeClass('is-active');
        $sliderNavItems.eq(index).addClass('is-active');
    });

}
