"use strict";

export function initInScope ($scope) {
    let $videos = $scope.find('.js-video-js');

    import('video.js').then(function (videojs){
        $videos.each(function () {
            videojs.default(this);
        });
    });
}