"use strict";
import {onEnterViewPort} from "@elements/viewport-utils";
import initModulesInScope from "@elements/init-modules-in-scope";

export function initInScope($scope) {
    $scope.find('.js-load-in-viewport').each(function() {
        let $base = $(this);
        let $container = $base.find('.js-load-in-viewport__container');
        let $template = $base.find('.js-load-in-viewport__template');
        let $loadingOverlay = $base.find('.js-load-in-viewport__loading-overlay');
        onEnterViewPort($base, () => {
            $container.html($template.html());
            initModulesInScope($container);
            $loadingOverlay.attr('hidden', true);
        });
    });
}