import $ from 'jquery';

// import 'popper.js';
import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';
// import 'bootstrap/js/dist/tooltip';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as ajaxInclude from './scripts/ajaxInclude';
app.modules.ajaxInclude = ajaxInclude.initInScope;

import * as ajaxForm from '@elements/ajax-form';
app.modules.ajaxForm = ajaxForm.initInScope;

import * as goToLink from '@elements/go-to-link';
app.goToLink = goToLink.init;

import * as tocPackage from '@elements/toc';
let toc = tocPackage.create({
    linkClass: 'sr-only sr-only-focusable'
});
app.toc = toc.init;
app.modules.toc = toc.initInScope;

import * as lazyImg from '@elements/lazy-img';
app.modules.lazyImg = lazyImg.initInScope;

import * as lazyIframe from '@elements/lazy-iframe';
app.modules.lazyIframe = lazyIframe.initInScope;

import * as loadInViewport from './scripts/load-in-viewport';
app.modules.loadInViewport = loadInViewport.initInScope;

import * as shariff from './scripts/shariff';
app.modules.shariff = shariff.initInScope;

import * as videoJs from './scripts/videoJs';
app.modules.videoJs = videoJs.initInScope;

import * as parsley from '@elements/parsley-bootstrap-validation';
app.modules.parsley = parsley.initInScope;

import * as floatingLabel from '@elements/floating-labels';
app.modules.floatingLabel = floatingLabel.initInScope;

import * as lightbox from '@elements/lightbox';
app.modules.lightbox = lightbox.initInScope;

import * as multiSelect from './scripts/multiSelect';
app.modules.multiSelect = multiSelect.initInScope;

import * as nav from './scripts/nav';
app.nav = nav.init;

import * as apartmentFinder from './scripts/apartmentFinder';
app.apartmentFinder = apartmentFinder.init;

import * as onPageNav from './scripts/onPageNav';
if (!matchMedia('(max-width: 767px)').matches) {
    app.onPageNav = onPageNav.init;
}


import * as affixNavbar from './scripts/affix-navbar';
if (!matchMedia('(max-width: 767px)').matches) {
    app.affixNavbar = affixNavbar.init;
}

import * as affix from '@elements/affix';
app.modules.affix = affix.createInitInScope({
    mediaQuery: '(min-width: 768px)'
});

import * as centerActiveTab from './scripts/centerActiveTab';
app.modules.centerActiveTab = centerActiveTab.initInScope;

import * as hero from './scripts/hero';
app.modules.hero = hero.initInScope;

import * as rangeSlider from './scripts/rangeSlider';
app.modules.rangeSlider = rangeSlider.initInScope;

import * as slider from '@elements/slider';
app.modules.slider = slider.initInScope;

// import * as smoothscroll from '@elements/smooth-scroll';
// app.modules.smoothscroll = smoothscroll.initInScope;
// app.smoothscroll = smoothscroll.init;

app.modules.galleryCarousel = slider.createInitInScope('.js-gallery-carousel', {
    slidesToShow: 1,
    prevArrow: "<button class=\"btn btn-outline-white slick-prev slick-arrow\" aria-label=\"Previous\" type=\"button\" style=\"\" aria-disabled=\"false\"><span class=\"icon icon-arrow-left\"></span></span></button>",
    nextArrow: "<button class=\"btn btn-outline-white slick-next slick-arrow\" aria-label=\"Next\" type=\"button\" style=\"\" aria-disabled=\"false\"><span class=\"icon icon-arrow-right\"></span></button>"
});

app.modules.textImageSlider = slider.createInitInScope('.js-text-image-slider', {
    slidesToShow: 1,
    adaptiveHeight: true
});

app.modules.imgListSliderLandscape = slider.createInitInScope('.js-img-list-slider-landscape', {
    slidesToShow: 2.8,
    responsive: [
        {
            breakpoint: 1399,
            settings: {
                slidesToShow: 2.3,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 1.8,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1.2,
            }
        }
    ]
});

app.modules.imgListSliderPortrait = slider.createInitInScope('.js-img-list-slider-portrait', {
    slidesToShow: 3,
    responsive: [
        {
            breakpoint: 1399,
            settings: {
                slidesToShow: 2.7,
            }
        },
        {
            breakpoint: 767,
            settings: {
                slidesToShow: 2.4,
            }
        },
        {
            breakpoint: 575,
            settings: {
                slidesToShow: 1.2,
            }
        }
    ]
});

app.modules.navTeaserSlider = slider.createInitInScope('.js-nav-teaser-slider', {
    slidesToShow: 3,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 4,
            }
        }, {
            breakpoint: 1199,
            settings: {
                slidesToShow: 3.2,
            }
        }, {
            breakpoint: 575,
            settings: {
                slidesToShow: 1,
                centerMode: true
            }
        }
    ]
});

import * as hotspots from './scripts/hotspots';
app.modules.hotspots = hotspots.initInScope;

import * as sujetSlider from './scripts/sujet-slider';
app.modules.sujetSlider = sujetSlider.initInScope;

import * as formMap from './scripts/formMap';
app.modules.formMap = formMap.initInScope;

import * as dragIndicator from './scripts/dragIndicator';
app.modules.dragIndicator = dragIndicator.initInScope;

import * as countUp from './scripts/countUp';
app.modules.countUp = countUp.initInScope;

import * as fileInput from './scripts/file-input';
app.modules.fileInput = fileInput.initInScope;

import * as overlay from './scripts/overlay';
app.overlay = overlay.init;
app.modules.overlay = overlay.initInScope;

import * as typeahead from '@elements/typeahead';
app.modules.typeahead = typeahead.initInScope;

import * as recaptcha from './scripts/recaptcha';
app.modules.recaptcha = recaptcha.initInScope;

import * as datepicker from '@elements/datepicker';
app.modules.datepicker = datepicker.initInScope;

import * as datepickerRange from '@elements/datepicker-range';
app.modules.datepickerRange = datepickerRange.initInScope;

// todo debug only
// function visualizeJs() {
//     let styles = Array.from(new Set($('[class^="js-"], [class*=" js-"]')
//         .toArray()
//         .map(element => Array.from(element.classList))
//         .flat()
//         .filter(x => x.startsWith('js-')))
//     ).map(cssClass => {
//         let color = '#' + ("000000" + Math.random().toString(16).slice(2, 8).toUpperCase()).slice(-6);
//         return (`
//             .${cssClass} {
//                 outline: 1px solid ${color};
//                 position: relative;
//             }
//             .${cssClass}:after {
//                 content: '${cssClass}';
//                 display: block;
//                 background-color: ${color};
//                 position: absolute;
//                 top: 0;
//                 right: 0;
//                 font-size: 13px;
//                 padding: .2rem .4rem;
//             }
//         `);
//     });
//
//     $(document.body).append('<style>' + styles.join('') + '</style>');
// }

// visualizeJs();

(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);