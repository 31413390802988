export function init() {
    let $target = $('.js-apartment-finder__target-form');
    let $scrollTarget = $('.js-apartment-finder__scroll-target');
    let $form = $('.js-apartment-finder__form');
    let $svg = $('.js-apartment-finder__svg-wrapper');
    let $labelButtons = $('.js-apartment-finder__label-btn');

    $form.on('submit', function (evt) {
        let $form = $(this);
        evt.preventDefault();

        let formEntries = Array.from(new FormData(this).entries());

        // Reset all inputs
        let names = removeDuplicates($form.find(':input[name]').toArray().map(x => x.name));
        let changedInputs = $target.find(':input')
            .toArray()
            .filter((input) => names.includes(input.name))
            .map(function (input) {
                $(input).val(null);

                return input;
            });
        
        // Set Values
        changedInputs = changedInputs.concat(formEntries.map(function ([name, value]) {
            let $input = $target.find(`:input[name="${name}"]`);
            if ($input.is('select')) {
                let $options = $input.find(`option[value=${value}]`);
                $options.each((index, option) => $(option).prop('selected', 'selected'));
            }

            return $input[0];
        }));

        changedInputs = removeDuplicates(changedInputs);

        $(changedInputs).trigger('change');
        let scrollPosition = window.scrollY;
        location.hash = $scrollTarget.attr('id');
        window.scrollTo(0, scrollPosition);
        $([document.documentElement, document.body]).animate({
            scrollTop: $scrollTarget.offset().top
        }, 230);

    });

    $svg.on('click', '[data-name][data-value]', function () {
        handleLabelClick($form, $(this));
    });

    console.log('$labelButtons', $labelButtons);
    $labelButtons.on('click', function (evt) {
        evt.preventDefault();
        handleLabelClick($form, $(this));
    });

    $form.on('change', function (evt) {
        let isChecked = $(evt.target).prop('checked');

        let $svgElement = $svg.find(`[data-name="${evt.target.name}"][data-value="${evt.target.value}"]`);
        if ($svgElement && $svgElement.length) {
            let classes = $svgElement.attr('class');
            if (isChecked) {
                $svgElement.attr('class', classes + ' is-active');
            } else {
                $svgElement.attr('class', classes.replace(/is-active/g, '').trim());
            }
        }

        let $labelButton = $labelButtons.filter(`[data-name="${evt.target.name}"][data-value="${evt.target.value}"]`);
        if ($labelButton && $labelButton.length) {
            if (isChecked) {
                $labelButton.addClass('is-active');
            } else {
                $labelButton.removeClass('is-active');
            }
        }
    })
}

function handleLabelClick($form, $label) {
    let name = $label.data('name');
    let value = $label.data('value');

    if (typeof name !== "undefined" && typeof value !== "undefined") {
        let $checkbox = $form.find(`:input[name="${name}"][value="${value}"]`);
        let newCheckedState = !$checkbox.prop('checked');
        $checkbox.prop('checked', newCheckedState).trigger('change');
    }
}

function removeDuplicates(array) {
    return Array.from(new Set(array));
}
