import {CountUp} from 'countup.js';
import {onEnterViewPort} from '@elements/viewport-utils';

const defaultOptions = {
    separator: '',
    decimal: '.'
};

export function initInScope($scope) {
    $scope.find('.js-count-up').each(function () {
        let number = +$(this).data('count-up-value') || +$(this).text();
        if (!isNaN(number)) {

            let countUp = new CountUp(this, +$(this).text(), defaultOptions);
            onEnterViewPort($(this), () => countUp.start());
        }
    })
}