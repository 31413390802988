import {onEnterViewPort} from "@elements/viewport-utils";
import {
    default as initMap,
    setMarker,
    loadGoogleMapsAPI,
    showInfoBoxByMarker,
    closeInfoBoxByMarker,
    setActiveMarkerStyle,
    setDefaultMarkerStyle,
    setDefaultClusterStyle,
    setActiveClusterStyle
} from "@elements/google-map";
import fetch from '@elements/fetch';
import {isElementInViewport} from '@elements/viewport-utils'
import {disable, enable} from "./affix-navbar";

export function initInScope($scope) {
    let $maps = $scope.find('.js-form-map');

    if ($maps && $maps.length) {
        $maps.each(function (index, element) {
            let $element = $(element);
            let $form = $element.find('.js-form-map__form');
            let $mapCanvas = $element.find('.js-form-map__map');
            let mapObj = null;

            if ($mapCanvas && $mapCanvas.length) {
                onEnterViewPort($mapCanvas, function () {
                    initMapCanvas($element, $form)
                        .then((newMapObj) => mapObj = newMapObj)
                        .catch(() => {
                        });
                }, {
                    offset: window.innerHeight * 1.5
                });
            }

            $element.on('success.ajax-form', function () {
                initMapCanvas($element, $form)
                    .then((newMapObj) => mapObj = newMapObj)
                    .catch(() => {
                    });
            });

            $form.on('change', function formChangeHandler() {
                if (mapObj) {
                    fetchPois($element, $form, $mapCanvas, mapObj);
                }
            });
        });
    }
}

function initMapCanvas($element, $form) {
    let $mapCanvas = $element.find('.js-form-map__map');

    if ($mapCanvas && $mapCanvas.length) {
        return loadGoogleMapsAPI().then(function () {
            const poiStyles = {
                default: {
                    default: {
                        url: '/static/build/img/map/marker/marker.svg',
                        size: new google.maps.Size(74, 99),
                        scaledSize: new google.maps.Size(74, 99),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(37, 99)
                    },
                    active: {
                        url: '/static/build/img/map/marker/marker-active.svg',
                        scaledSize: new google.maps.Size(74, 99),
                        size: new google.maps.Size(74, 99),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(37, 99)
                    }
                },
                active: {
                    default: {
                        url: '/static/build/img/map/marker/marker-active.svg',
                        size: new google.maps.Size(74, 99),
                        scaledSize: new google.maps.Size(74, 99),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(37, 99)
                    },
                    active: {
                        url: '/static/build/img/map/marker/marker-active.svg',
                        scaledSize: new google.maps.Size(74, 99),
                        size: new google.maps.Size(74, 99),
                        origin: new google.maps.Point(0, 0),
                        anchor: new google.maps.Point(37, 99)
                    }
                },
                ...createPoiStyles(['education', 'shopping', 'fitness', 'transport', 'restaurant', 'hotel', 'medical'])
            };

            const infoBoxOptions = {
                alignBottom: true,
                pixelOffset: new google.maps.Size(
                    matchMedia('(max-width: 767px)').matches ? -130 : -158,
                    0
                ),
                boxStyle: {
                    width: matchMedia('(max-width: 767px)').matches ? "270px" : "300px",
                    background: "#fff",
                    padding: matchMedia('(max-width: 767px)').matches ? "1rem" : "1.25rem"
                },
                boxClass: "infobox",
                closeBoxURL: "/static/img/map/close.svg"
            };

            let clusterStyle = {
                height: 65,
                width: 65,
                textSize: 0,
                textColor: "transparent"
            };
            let clusteringOptions = {
                default: {
                    gridSize: 30,
                    styles: [{
                        ...clusterStyle,
                        url: "/static/build/img/map/marker/cluster.svg"
                    }]
                },
                active: {
                    gridSize: 30,
                    styles: [{
                        ...clusterStyle,
                        url: "/static/build/img/map/marker/cluster-active.svg"
                    }]
                },
            };

            let options = $element.data('google-map-options');

            let mapObj = initMap({
                element: $mapCanvas[0],
                mapOptions: options,
                poiStyles,
                infoBoxOptions,
                clustering: true,
                clusteringOptions,
                onActivateMarker: (marker, mapObj) => onActivateMarker($element, marker, mapObj),
                onDeactivateMarker: (marker, mapObj) => onDeactivateMarker($element, marker, mapObj),
            });

            fetchPois($element, $form, $mapCanvas, mapObj);

            $element.find('.js-form-map__item[data-form-map-item-id]')
                .on('mouseenter', function () {
                    let id = $(this).data('form-map-item-id');
                    let activeMarker = mapObj.marker.filter(marker =>
                        !!marker.dataId && marker.dataId.toString() === id.toString())[0];

                    if (activeMarker) {
                        setActiveMarkerStyle(activeMarker, mapObj);

                        if (mapObj.clusteringPromise) {
                            mapObj.clusteringPromise.then((markerCluster) => {
                                let clusters = markerCluster.getClusters();

                                clusters.forEach(cluster => setDefaultClusterStyle(cluster, mapObj));

                                let activeCluster = clusters.find(cluster =>
                                    cluster.getMarkers().find(marker => marker === activeMarker)
                                );

                                setActiveClusterStyle(activeCluster, mapObj);
                            });
                        }
                    }
                })
                .on('mouseleave', function () {
                    let id = $(this).data('form-map-item-id');
                    let activeMarker = mapObj.marker.filter(marker =>
                        !!marker.dataId && marker.dataId.toString() === id.toString())[0];

                    if (activeMarker) {
                        setDefaultMarkerStyle(activeMarker, mapObj);
                    }

                    if (mapObj.clusteringPromise) {
                        mapObj.clusteringPromise.then((markerCluster) => {
                            markerCluster.getClusters().forEach(
                                cluster => setDefaultClusterStyle(cluster, mapObj)
                            );
                        });
                    }
                });
            return Promise.resolve(mapObj);
        }).catch(function (a, b, c) {
            console.error(a, b, c);
        });
    }

    return Promise.reject();
}

function fetchPois($element, $form, $mapCanvas, mapObj) {
    fetch($mapCanvas.data('form-map-url') || $element.data('form-map-url'), {
        body: new URLSearchParams(new FormData($form[0]))
    })
        .then(response => response.json())
        .then((pois) => {
            setMarker(mapObj, pois)
        });
}

function onActivateMarker($element, marker, mapObj) {
    if (marker.detailInfoBoxUrl) {
        let infoBoxPromise = showInfoBoxByMarker(
            marker,
            `<div class="my-2">
                <div class="loading-spinner" aria-label="Loading...">
                    <div class="loading-spinner__item loading-spinner__item--1"></div>
                    <div class="loading-spinner__item loading-spinner__item--2"></div>
                    <div class="loading-spinner__item loading-spinner__item--3"></div>
                </div>
            </div>`,
            mapObj,
            {
                pixelOffset: new google.maps.Size(-158, marker.icon.anchor.y * -1 - 16),
            }
        );

        let contentPromise = fetch(marker.detailInfoBoxUrl).catch(function (a, b, c) {
            console.error(a, b, c);
        });

        $.when(infoBoxPromise, contentPromise).then(function (infoBox, infoBoxResponse) {
            if (infoBox.getVisible()) {
                infoBoxResponse.json().then((response) => {
                    // open the new info box with the new content only if the old one is still open
                    if (response.success && response.html) {
                        infoBox.setContent(response.html);
                    } else {
                        closeInfoBoxByMarker(marker, mapObj);
                    }
                });

            }
        });
    }

    if (marker.dataId) {
        let $item = $element.find(`.js-form-map__item[data-form-map-item-id="${marker.dataId}"]`);
        $item.addClass('is-active');

        if ($item && $item.length && !isElementInViewport($item)) {
            let itemOffset = $item.offset().top;
            let scrollPosition = window.scrollY;

            disable(false);
            $('html, body').stop().animate({
                scrollTop: itemOffset > scrollPosition
                    ? itemOffset - window.innerHeight + $item.height() + 100
                    : itemOffset - 100
            }, 200, function () {
                enable();
            });

        }
    }
}

function onDeactivateMarker($element, marker, mapObj) {
    closeInfoBoxByMarker(marker, mapObj);

    if (marker.dataId) {
        $element.find(`.js-form-map__item[data-form-map-item-id="${marker.dataId}"]`).removeClass('is-active');
    }
}

function createPoiStyles(names) {
    return names.reduce((obj, name) => {
        obj[name] = {
            default: {
                url: `/static/build/img/map/marker/${name}.svg`,
                size: new google.maps.Size(65, 65),
                scaledSize: new google.maps.Size(65, 65),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(32, 32),
            },
            active: {
                url: `/static/build/img/map/marker/${name}-active.svg`,
                size: new google.maps.Size(65, 65),
                scaledSize: new google.maps.Size(65, 65),
                origin: new google.maps.Point(0, 0),
                anchor: new google.maps.Point(32, 32),
            }
        };

        return obj;
    }, {});
}