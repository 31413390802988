import loadScript from "@elements/load-script";

const API_KEY = '6LdyePYZAAAAAMj3XvFu-dz-DhMS5si_5-iXc-Ee';
export function initInScope($scope) {
    let $elements = $scope.find('.js-recaptcha');

    loadRecaptchaAPI().then(function () {
        $elements.toArray().forEach(element => {
            let isValidated = false;

            $(element).on('submit', function (evt) {
                if (window['grecaptcha'] && !isValidated) {
                    evt.preventDefault();
                    grecaptcha.ready(function () {
                        grecaptcha.execute(API_KEY, {action: 'submit'}).then(function (token) {
                            $(element).append(`<input type="hidden" name="g-recaptcha-response" value="${token}" />`);
                            isValidated = true;
                            $(element).trigger('submit');
                        });
                    });
                }
            });
        })
    })
}

export function loadRecaptchaAPI() {
    return loadScript(`https://www.google.com/recaptcha/api.js?render=${API_KEY}`);
}
