import 'bootstrap/js/dist/scrollspy';

export function init() {
    $('body').scrollspy({
        target: '.js-on-page-nav__nav',
        offset: 500
    });

    let $element = $('.js-on-page-nav');

    $(window).on('scroll', () => {
        if (window.pageYOffset > 500) {
            $element.addClass('is-visible');
        } else {
            $element.removeClass('is-visible');
        }
    });




    /* find links + add to list */
    let $areas = $('.js-page-nav__area');

    if ($areas && $areas.length >= 2) {
        $('.js-on-page-nav').attr('hidden', null);

        let items = $areas.toArray().map(area => ({
            name: $(area).data('name'),
            id: $(area).attr('id')
        }));

        $('.js-on-page-nav__nav').append(items.map(x =>
            `<li class="nav-item"><a href="#${x.id}" class="nav-link js-on-page-nav__link">${x.name}</a></li>`
        ).join('')).attr('hidden', null);

    }

}