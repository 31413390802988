"use strict";

export function initInScope ($scope) {
    let $slider = $scope.find('.js-range-slider');

    import('nouislider').then(function (noUiSlider) {
        $slider.each(function () {
            let $container = $(this);
            let slider = $container.find('.js-range-slider__slider')[0];
            let $minInput = $container.find('.js-range-slider__min');
            let $maxInput = $container.find('.js-range-slider__max');
            let $minTextInput = $container.find('.js-range-slider__min-text');
            let $maxTextInput = $container.find('.js-range-slider__max-text');

            let min = $container.find('.js-range-slider__min-value').val();
            let max = $container.find('.js-range-slider__max-value').val();

            noUiSlider.create(slider, {
                start: [
                    $minInput.val() || min,
                    $maxInput.val() || max
                ],
                connect: true,
                range: {
                    'min': parseInt(min),
                    'max': parseInt(max)
                }
            });

            slider.noUiSlider.on('update', function( values, handle ) {
                let value = +values[handle];
                if ( handle ) {
                    setMaxText(value);
                } else {
                    setMinText(value);
                }
            });

            slider.noUiSlider.on('change', function ( values, handle ) {
                if ( handle ) {
                    setMax(values[handle]);
                    $maxInput.trigger('change');
                } else {
                    setMin(values[handle]);
                    $minInput.trigger('change');
                }
            });

            function setMin(value) {
                value = +value || min;

                $minInput.val(+value === min ? null: value);
                slider.noUiSlider.set([value, null]);
                setMinText(value);
            }

            function setMinText(value) {
                value = +value || min;
                let text = value.toLocaleString(_config.lang);

                $minTextInput.text(text);
                $minInput.data('text', text);
            }

            function setMax(value) {
                value = +value || max;

                $maxInput.val(+value === max ? null: value);
                slider.noUiSlider.set([null, value]);
                setMaxText(value);
            }

            function setMaxText(value) {
                value = +value || max;
                let text = value.toLocaleString(_config.lang);

                $maxTextInput.text(text);
                $maxInput.data('text', text);
            }
        })
    });
}