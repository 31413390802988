// Hide Header on on scroll down
let didScroll;
let lastScrollTop = 0;
let delta = 1;
let navbarHeight;
let defaultTopPosition;
let $navbar = $();
let $navbarContainer  = $();
let isTransitioning = false;
let isFixed = false;
let isDisabled = false;

function scrollHandler() {
    let scrollTopPosition = $(window).scrollTop();
    if (Math.abs(lastScrollTop - scrollTopPosition) <= delta)
        return;

    if (isDisabled) {
        lastScrollTop = scrollTopPosition;
        return;
    }

    let navbarOffset = $navbar.offset().top;

    if (scrollTopPosition <= defaultTopPosition) {
        $navbar.removeClass('is-affix').css('top', 0).removeClass('is-transitioning');
        isFixed = false;
        isTransitioning = true;
        return;
    }

    if (lastScrollTop > scrollTopPosition) {
        /*scroll up*/

        if (!isFixed) {
            if (isTransitioning) {
                if (navbarOffset >= scrollTopPosition) {
                    /*end of transtion*/
                    isTransitioning = false;
                    isFixed = true;
                    $navbar.css('top', 0);
                    $navbar.addClass('is-affix');
                    $navbar.removeClass('is-transitioning');
                }
            } else {
                $navbar.addClass('is-transitioning').css({
                    top: scrollTopPosition - navbarHeight
                });
                isTransitioning = true;
            }
        }
    } else if (lastScrollTop < scrollTopPosition) {
        /*scroll down*/

        if (isFixed) {
            $navbar.removeClass('is-affix');

            $navbar.css({
                top: lastScrollTop
            }).addClass('is-transitioning');

            isTransitioning = true;
            isFixed = false;
        }

        if (isTransitioning) {
            if (navbarOffset + navbarHeight <= scrollTopPosition) {
                isTransitioning = false;
            }
        }
    }

    lastScrollTop = scrollTopPosition;
}

export function init() {
    $navbar = $('.js-affix-nav-bar');
    $navbarContainer = $navbar.closest('.js-affix-nav-bar__placeholder');
    defaultTopPosition = $navbarContainer.offset().top;

    $(window).one('scroll', function () {
        $navbarContainer.css('overflow', 'hidden');
        navbarHeight = $navbarContainer[0].getBoundingClientRect().height;
        $navbarContainer.css('overflow', '');
        /*get exact height (jquery returns rounded value)*/
        $navbarContainer.height(navbarHeight);
    });

    $(window).scroll(function (event) {
        didScroll = true;
    });

    requestAnimationFrame(function animationFrameHandler() {
        if (didScroll) {
            scrollHandler();
            didScroll = false;
        }
        requestAnimationFrame(animationFrameHandler);
    });
}

export function disable(disableAtAffixState) {
    if (disableAtAffixState) {
        $navbar.addClass('is-affix').css('top', 0).removeClass('is-transitioning');
        isFixed = true;
        isTransitioning = false;
    } else {
        $navbar.removeClass('is-affix').css('top', 0).removeClass('is-transitioning');
        isFixed = false;
        isTransitioning = false;
    }

    isDisabled = true;
}

export function enable() {
    isDisabled = false;

    lastScrollTop = Number.NEGATIVE_INFINITY;
    scrollHandler();
}

export function getAffixNavBarHeight() {
    return $('.js-affix-nav-bar').height() || 0;
}

export function isInDefaultState() {
    return !$navbar.hasClass('is-transitioning') && !$navbar.hasClass('is-affix');
}


