import {disable, enable} from './affix-navbar';

export function init() {
    $('.js-nav__link').on('click', function (evt) {
        let $currentLink = $(this);
        let $nav = $currentLink.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');
        let $navItem = $currentLink.closest('.js-nav__item');

        evt.preventDefault();

        $nav.addClass('is-open');
        $navItems.removeClass('is-open');
        $navItem.addClass('is-open');

        if (!matchMedia('(max-width: 767px)').matches) {
            disable(true);
        }
    });

    $('.js-nav__close').on('click', function () {
        let $close = $(this);
        let $nav = $close.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');

        if (!matchMedia('(max-width: 767px)').matches) {
            $nav.removeClass('is-open');
        }

        $navItems.removeClass('is-open');

        if (!matchMedia('(max-width: 767px)').matches) {
            enable();
        }
    });

    $('.js-nav__toggle').on('click', function () {
        let $close = $(this);
        let $nav = $close.closest('.js-nav');
        let $navItems = $nav.find('.js-nav__item');

        $nav.toggleClass('is-open');
        $navItems.removeClass('is-open');

        if ($nav.hasClass('is-open')) {
            if (matchMedia('(max-width: 767px)').matches) {
                $('html').addClass('is-scrolling-disabled');
            }
        } else {
            if (matchMedia('(max-width: 767px)').matches) {
                $('html').removeClass('is-scrolling-disabled');
            } else {
                enable();
            }
        }
    });
}